<template>
  <div class="container">
    <div class="px-4">
      <h1>Imports</h1>
      <Alert :variant="alertVariant" v-if="alertMessage">{{ alertMessage }}</Alert>
    </div>
    <div class="overflow-x-hidden px-4 mt-10">
      <div class="table-overflow">
        <GenericTable
          :columns="columns"
          :data="data"
          :actions="true"
          :loading="loading"
          :pagination="pagination"
          :totalName="totalName"
          stacked
          @updateCurrentPage="updateCurrentPage"
          @sort="sort"
        >
          <template #jobId="{ row }">
            <span v-b-tooltip.hover="row.uuid" class="dashline">
              {{ formatUuid(row.uuid) }}
            </span>
          </template>
          <template #completedAt="{ row }">
            <DateTooltip :date="row.completedAt" />
          </template>
          <template #status="{ row }">
            <Badge variant="success" label="Completed" v-if="row.status === 'completed'" />
            <Badge variant="secondary" label="In Progress" v-else-if="row.status === 'in-progress'" />
            <Badge variant="light" label="Pending" v-else-if="row.status === 'pending'" />
            <Badge variant="danger" label="Failed" v-else-if="row.status === 'failed'" />
          </template>
          <template #service="{ row }">
            <p>{{ row.service.charAt(0).toUpperCase() + row.service.slice(1) }}</p>
          </template>
        </GenericTable>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth } from '@rafflebox-technologies-inc/auth-service-sdk';

import JobService from '@/lib/job-service-v2.js';
import Alert from '@/components/ui/Alert.vue';
import GenericTable from '@/components/GenericTable.vue';
import DateTooltip from '@/components/DateTooltip.vue';
import Badge from '@/components/rbComponents/Badge.vue';

export default {
  name: 'Imports',
  components: {
    Alert,
    Badge,
    GenericTable,
    DateTooltip
  },
  async mounted() {
    this.getImports();
  },
  data() {
    return {
      columns: [
        {
          name: 'jobId',
          label: 'Job ID',
          classes: 'w-auto'
        },
        {
          name: 'fileName',
          label: 'File Name',
          classes: 'w-auto'
        },
        {
          name: 'status',
          label: 'Job Status',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'failedReason',
          label: 'Failed Reason',
          classes: 'w-auto'
        },
        {
          name: 'completedAt',
          label: 'Completed at',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'service',
          label: 'Import Type',
          classes: 'w-auto'
        },
        {
          name: 'createdByName',
          label: 'Created by',
          classes: 'w-auto'
        }
      ],
      data: [],
      search: null,
      sortBy: 'completedAt',
      sortDir: 'desc',
      pagination: {
        page: 0,
        pageSize: 10,
        total: 0
      },
      alertMessage: null,
      alertVariant: 'red',
      totalName: 'Imports',
      loading: true,
      error: null
    };
  },
  methods: {
    async updateSearch(search) {
      this.search = search;
      await this.updateCurrentPage(0);
      await this.getImports();
    },
    async getImports() {
      try {
        this.loading = true;
        const sessionUser = await getAuth().sessionUser();
        const organizationId = this.isRbAdmin ? undefined : sessionUser.organizationUuid;

        const params = {
          organizationId,
          search: this.search,
          page: this.currentPage,
          pageSize: this.pageSize,
          sortDir: this.sortDir,
          sortBy: this.sortBy
        };

        const { data, pagination } = await JobService.listJobs(params);
        this.pagination = pagination;
        this.data = data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.alertMessage = `Failed to load imports: ${error.message}`;
        this.alertVariant = 'red';
        return [];
      }
    },
    async updateCurrentPage(page) {
      this.currentPage = page;
      await this.getImports();
    },
    async sort(sortBy, sortDir) {
      this.sortBy = sortBy;
      this.sortDir = sortDir;
      await this.getImports();
    }
  }
};
</script>

<style scoped>
.dashline {
  border-bottom: 1px dashed;
}
</style>
